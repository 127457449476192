// const Pay = () => import('src/pages/Pay.vue');
// const ErrorPage = () => import('src/pages/Error.vue');
const AuthBasic = () => import('src/pages/Auth.vue');

const BasePos = () => import('src/pages/BasePos.vue');
const Mpos = () => import('src/pages/Mpos.vue');
const Tpos = () => import('src/pages/Tpos.vue');

let routes = [
  {
    path: '/',
    redirect: '/auth'
  },
  {
    path: '/auth',
    name: 'auth-basic',
    components: { default: AuthBasic }
  },
  // {
  //   path: '/mpos',
  //   name: 'qcpos-m',
  //   components: { default: Mpos }
  // },
  // {
  //   path: '/tpos',
  //   name: 'qcpos-t',
  //   components: { default: Tpos }
  // },
  {
    path: '/pos',
    name: 'pos',
    component: BasePos,
    redirect: '/pos/m',
    children: [
      {
        path: 'm',
        name: 'mpos',
        components: { default: Mpos }
      },
      {
        path: 't',
        name: 'tpos',
        components: { default: Tpos }
      }
    ]
  }
  // {
  //   path: '/error',
  //   name: 'error',
  //   component: ErrorPage,
  //   props: true
  // }
];

export default routes;