import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import VueRouter from 'vue-router';
import VueSimpleAlert from 'vue-simple-alert';
import axios from 'axios';

import routes from './routes/routes';
import store from  './store';
import './registerServiceWorker'

import ErrorCodes from './error-codes';

import { fauth } from './firebase';
import { _ } from 'core-js';

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VueRouter);
Vue.use(VueSimpleAlert);

const router = new VueRouter({
  routes,
  mode: 'history', // 從url拿掉#，避免line衝突 
  base: process.env.BASE_URL,
  linkActiveClass: 'active'
});

/// Before-route-change check for quick re-signin.
router.beforeEach((to, from, next) => {
  // console.warn('router.beforeEach', from, to);
  
  if (to.name == 'auth-basic') {
    if (store.state.shopId <= 0) next(); 
    else next({ path: `/pos/${store.state.uiMode}` });
  }
  else if (['qcpos-m', 'qcpos-t', 'mpos', 'tpos'].indexOf(to.name) >= 0) {
    //FIXME: If MPOS/TPOS page is requested, we must check stored user session and determine
    // states so that it renders properly.
    store.commit('loadUserSession');
    console.warn(`[Vue router beforeEach] ${to.name} is requested, but shop ID is ${store.state.shopId}...`);

    if (store.state.shopId <= 0) next({ path: '/auth' });
    else next();
  }
  else {
    if (store.state.shopId <= 0) next({ path: '/auth' });
    // If it has been logged in, go to MPOS/TPOS.
    else next({ path: `/pos/${store.state.uiMode}` });
  }
});

/// Init an auth-state listener of Firebase.
fauth.onAuthStateChanged(user => {
  if (!user)//NOTE: In case Firebase accidentally logs out.
    store.commit('logout');
});

/// Init error-code global method.
Vue.prototype.$errorCodeToMessage = code => {
  return ErrorCodes[code];
};

/// Instantiate app.
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),

  //FIXME: Init Vuex store here.
  // created() {
  //   this.$store.commit('initState');
  // }

}).$mount('#app')
