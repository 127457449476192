function SoundManager() {
  this.enabled = true;
  
  // this.tracks = {};

  this.currentTrackName = null;
}
SoundManager.priority = ['pending', 'incoming'];
SoundManager.trackSrc = {
  incoming: {
    basic: 'chime.mp3', piano: 'incoming_orders.mp3'
  },
  pending: {
    basic: 'dingaling.mp3', piano: 'pending_orders.mp3'
  }
};
SoundManager.tracks = { };
SoundManager.soundStarter = function() {
  console.warn(`[SoundManager] soundStarter triggered.`, SoundManager.tracks);
  for (let trackName in SoundManager.tracks) {
    let track = SoundManager.tracks[trackName];
    console.warn(`[SoundManager] soundStater inits track ${trackName}.`);
    track.play();
    track.pause();
    track.currentTime = 0;
  }
  // document.body.removeEventListener('touchstart', SoundManager.soundStarter, false);
  document.body.removeEventListener('mousedown', SoundManager.soundStarter, false);
};

SoundManager.instance = undefined;
SoundManager.get = function() {
  if (!SoundManager.instance)
    SoundManager.instance = new SoundManager();
  return SoundManager.instance;
};

SoundManager.prototype.init = function() {
  console.warn(`[SoundManager] init. Listening to soundStarter event...`);
  // document.body.addEventListener('touchstart', SoundManager.soundStarter, false);
  document.body.addEventListener('mousedown', SoundManager.soundStarter, false);
};

SoundManager.prototype.enable = function(_enable) {
  if (!_enable)
    this.stop();
  this.enabled = _enable;
};

SoundManager.prototype.loadTrack = function(_track, _type) {
  let src = SoundManager.trackSrc[_track][_type];
  console.warn(`[SoundManager] loadTrack: ${src}`);
  if (!src) return;
  SoundManager.tracks[_track] = new Audio(`${process.env.BASE_URL}sound/${src}`);
  console.warn(`[SoundManager] loadTrack loaded tracks:`, SoundManager.tracks);
};

SoundManager.prototype.play = function(_track, _loop) {
  if (!this.enabled) return;
  if (!this.isTrackPrioritized(_track)) return;
  
  console.warn(`[SoundManager] Will play track ${_track} (loop? ${_loop === true})`);

  let self = this;
  if (this.currentTrackName) this.stop();
  let track = SoundManager.tracks[_track];
  if (!track) return;
  track.loop = _loop || false;
  track.onended = function() { self.currentTrackName = null; };
  this.currentTrackName = _track;
  track.play();
};

SoundManager.prototype.stop = function() {
  if (!this.enabled) return;
  if (!this.currentTrackName) return;
  let track = SoundManager.tracks[this.currentTrackName];
  track.pause();
  track.currentTime = 0;
  this.currentTrackName = null;
};

SoundManager.prototype.isTrackPrioritized = function(_track) {
  if (!this.currentTrackName) return true;
  return SoundManager.priority.indexOf(_track) < SoundManager.priority.indexOf(this.currentTrackName);
};


export default SoundManager;