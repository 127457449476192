import Vue from 'vue';

import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore'

const fapp = firebase.initializeApp({
  apiKey: `${process.env.VUE_APP_FIREBASE_API_KEY}`,
  projectId: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}`
})

fapp.firestore().settings({
  cacheSizeBytes: 31457280 //WARNING: Default is 40 MB.
});

fapp.firestore().enablePersistence()
.catch(err => {
  console.error('Firestore offline persistence failed', err.code);
  if (err.code == 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  }
  else if (err.code == 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

export const firestore = fapp.firestore();
export const fauth = fapp.auth();
export const fauthPersistence = firebase.auth.Auth.Persistence;
export const FirestoreDataEvent = new Vue();